<template>
  <div>
    <p v-if="isExperienceB">
      This is experience B
      <a href="http://www.fandango.com">Go and see a movie</a>
    </p>
    <p v-else>
      This is experience A
      <a href="http://www.catster.com">Click here to see some cats</a>
    </p>
  </div>
</template>

<script>
const vwoScript = `
window._vwo_code = window._vwo_code || (function(){
var account_id=298167,
settings_tolerance=2000,
library_tolerance=2500,
use_existing_jquery=false,
is_spa=1,
hide_element='body',
/* DO NOT EDIT BELOW THIS LINE */
f=false,d=document,code={use_existing_jquery:function(){return use_existing_jquery;},library_tolerance:function(){return library_tolerance;},finish:function(){if(!f){f=true;var a=d.getElementById('_vis_opt_path_hides');if(a)a.parentNode.removeChild(a);}},finished:function(){return f;},load:function(a){var b=d.createElement('script');b.src=a;b.type='text/javascript';b.innerText;b.onerror=function(){_vwo_code.finish();};d.getElementsByTagName('head')[0].appendChild(b);},init:function(){
window.settings_timer=setTimeout('_vwo_code.finish()',settings_tolerance);var a=d.createElement('style'),b=hide_element?hide_element+'{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}':'',h=d.getElementsByTagName('head')[0];a.setAttribute('id','_vis_opt_path_hides');a.setAttribute('type','text/css');if(a.styleSheet)a.styleSheet.cssText=b;else a.appendChild(d.createTextNode(b));h.appendChild(a);this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+(+is_spa)+'&r='+Math.random());return settings_timer; }};window._vwo_settings_timer = code.init(); return code; }());
`;
export default {
  computed: {
    isExperienceB() {
      return this.experience === 'B';
    },
  },
  methods: {
    load() {
      const ab = window.experience;
      this.experience = ab;
    },
  },
  beforeMount() {
    const hasScript = Boolean(document.getElementById('vwo-script'));
    if (hasScript) {
      return;
    }
    const script = document.createElement('script');
    script.id = 'vwo-script';
    script.type = 'text/javascript';
    script.innerHTML = vwoScript;
    const head = document.querySelector('head');
    head.insertBefore(script, head.firstChild);
  },
};
</script>

<style lang="scss" scoped>
</style>
